import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CalendarIcon from "../../assets/icons/Calendar.png";
import "./_leaveRequest.scss";
import Button from "../../components/Button/index";
import { useDispatch, useSelector } from "react-redux";
import { addLeave, deleteLeave, editLeaveCurrentUser } from "../../slices/leave";
import { getLeaveTypeId } from "../../utilities/getLeaveTypeId";
import Select from "../../components/Form/Select";
import Textarea from "../../components/Form/Textarea";
import DatePicker from "../../components/Form/DatePicker";
import Notice from "../../components/Notice";
import differenceInDays from "date-fns/differenceInDays";
function FormLeaveRequest(props) {
  const dispatch = useDispatch();
  const {
    disabled,
    row,
    handleClose,
    id,
    updateMyLeave,
    leaveTypes,
    setNotice,
    LeaveRequest,
    leaveTypeStatus,
    notice,
  } = props;
  const user = useSelector((state) => state.user.fetchMe);
  const [resetLeaveType, setResetLeaveType] = useState(false);

  const initialValues = {
    type: row ? row?.type?.name : "",
    description: row ? row?.description : "",
    leaveDate: {
      from: row
        ? {
            day: new Date(row?.dateStart).getDate(),
            month: new Date(row?.dateStart).getMonth() + 1,
            year: new Date(row?.dateStart).getFullYear(),
          }
        : null,
      to: row
        ? {
            day: new Date(row?.dateEnd).getDate(),
            month: new Date(row?.dateEnd).getMonth() + 1,
            year: new Date(row?.dateEnd).getFullYear(),
          }
        : null,
    },
  };
  const validationSchema = Yup.object({
    type: Yup.string().required("Type is Required!"),
    description: Yup.string()
      .min(10, "Description must be at least 10 characters")
      .required("Department description is required!"),
  });

  const onSubmit = async (values, submitProps) => {
    const data = {
      type: row ? getLeaveTypeId(leaveTypes, values?.type) : values?.type,
      description: values.description,
      dateStart:
        values.leaveDate?.from?.month +
        "/" +
        values.leaveDate?.from?.day +
        "/" +
        values.leaveDate?.from?.year,
      dateEnd:
        values.leaveDate?.to?.month +
        "/" +
        values.leaveDate?.to?.day +
        "/" +
        values.leaveDate?.to?.year,
    };

    updateMyLeave
      ? await dispatch(editLeaveCurrentUser({ data, id: row._id })).then(
          (res) => {
            if (res.error) {
              setNotice({
                text: res?.error?.message,
                open: true,
                state: "error",
              });
              submitProps.setStatus({ success: false });
              submitProps.setSubmitting(true);
            } else {
              if (res?.payload?.message) {
                setNotice({
                  text: res?.payload?.message,
                  open: true,
                  state: "success",
                });
                submitProps.setStatus({ success: true });
                submitProps.setSubmitting(true);
                setTimeout(() => {
                  handleClose(id);
                }, 1500);
              }
            }
          }
        )
      : await dispatch(addLeave(data)).then((res) => {
          if (res.error) {
            setNotice({
              text: res?.error?.message,
              open: true,
              state: "error",
            });
            submitProps.setStatus({ success: false });
            submitProps.setSubmitting(true);
          } else if (
            user?.balanceLeft <
            differenceInDays(
              new Date(
                values.leaveDate?.to?.month +
                  "/" +
                  values.leaveDate?.to?.day +
                  "/" +
                  values.leaveDate?.to?.year
              ),
              new Date(
                values.leaveDate?.from?.month +
                  "/" +
                  values.leaveDate?.from?.day +
                  "/" +
                  values.leaveDate?.from?.year
              )
            ) +
              1
          ) {
            setNotice({
              text: `Succes! Leave request succesfully submitted ,Your balance will be negative with

 ${Math.abs(
   differenceInDays(
     new Date(
       values.leaveDate?.to?.month +
         "/" +
         values.leaveDate?.to?.day +
         "/" +
         values.leaveDate?.to?.year
     ),
     new Date(
       values.leaveDate?.from?.month +
         "/" +
         values.leaveDate?.from?.day +
         "/" +
         values.leaveDate?.from?.year
     )
   ) -
     user?.balanceLeft +
     1
 ).toFixed(2)}`,
              open: true,
              state: "success",
            });
            setTimeout(() => {
              handleClose(id);
            }, 1500);
          } else {
            if (res?.payload?.message) {
              setNotice({
                text: res?.payload?.message,
                open: true,
                state: "success",
              });
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(true);
              setTimeout(() => {
                handleClose(id);
              }, 1500);
            }
          }
        });
  };
  const leaveOption =
    leaveTypeStatus === "succeeded" &&
    leaveTypes?.map((leave) => ({
      label: leave?.name,
      value: row ? leave.name : leave?._id,
    }));
  const leaveTypeStyle = {
    borderRadius: "14px",
    heigth: "65px",
    marginBottom: "35px",
    marginTop: "15px",
  };
    const handleCancelClick = () => {
      dispatch(deleteLeave(row?._id)).then((res) => {
        if (res.error) {
          setNotice({
            text: res?.error?.message,
            open: true,
            state: "error",
          });
        } else if (res?.payload?.message) {
          setNotice({
            text: res?.payload?.message,
            open: true,
            state: "success",
          });
          handleClose(id);
        }
      });
    };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <>
            <Form className="leave-request-form-wrraper">
              <div className="form-leave-request-wrapper">
                <div className="second-wrraper-form">
                  <DatePicker
                    calendarPopperPosition="bottom"
                    formik={formik}
                    type="date"
                    label="Leave Date"
                    name="leaveDate"
                    withIcon
                    rightIcon={CalendarIcon}
                    className={`${
                      disabled
                        ? "disabledselect form-control-input-with-icons-leave-request"
                        : row
                        ? "form-control-input-with-icons-leave-request "
                        : " form-control-input-with-icons-leave-request"
                    }
                
                `}
                    error={
                      formik.touched?.leaveDate?.from === true &&
                      formik.values.leaveDate.to === null
                        ? "Date is required!"
                        : null
                    }
                  />
                </div>
                <Select
                  label="Type"
                  id="type"
                  value={formik.values.type}
                  setResetValue={setResetLeaveType}
                  resetValue={resetLeaveType}
                  options={leaveOption}
                  isLeave
                  {...leaveTypeStyle}
                  onChange={(value) => {
                    formik.setFieldValue("type", value.value);
                  }}
                  className={`${
                    formik.errors.type && formik.touched.type
                      ? " selectLeaveRequest  error-select"
                      : disabled
                      ? "disabledselect selectLeaveRequest"
                      : "  selectLeaveRequest "
                  }`}
                  error={
                    formik.errors.type && formik.touched.type
                      ? formik.errors.type
                      : null
                  }
                />
                <Textarea
                  type="text"
                  label="Description"
                  name="description"
                  placeholder="Reason..."
                  className={disabled ? "disabled" : ""}
                />

                <div className="noticeUpdated">
                  {updateMyLeave && (
                    <Notice
                      open={notice.open}
                      text={notice.text}
                      state={notice.state}
                      className="notice"
                    />
                  )}
                  {!updateMyLeave && (
                    <Notice
                      open={notice.open}
                      text={notice.text}
                      state={notice.state}
                      className="notice "
                    />
                  )}
                </div>

                {LeaveRequest && (
                  <div className="edit-password-wrapper">
                    <Button
                      type="submit"
                      className={
                        !formik.isValid || formik.isSubmitting
                          ? " commun-save-button blue-disabled-button "
                          : "commun-save-button"
                      }
                      text={"Apply for Leave"}
                    />
                    <Button
                      type="reset"
                      text="Reset"
                      className={"commun-reset-button"}
                      onClick={() => setResetLeaveType(true)}
                    />
                    
                  </div>
                )}

                {!disabled & updateMyLeave ? (
                  <>
                  <div className="edit-password-wrapper">
                    <Button
                      type="submit"
                      className={
                        !formik.isValid ||
                        formik.values.leaveDate.to === null ||
                        formik.isSubmitting
                          ? " commun-save-button disabled-button "
                          : "commun-save-button "
                      }
                      text={"Edit Leave "}
                    />
                      <Button
                  type="button"
                  className={
                    !formik.isValid ||
                    formik.values.leaveDate.to === null ||
                    formik.isSubmitting
                      ? "commun-reset-button disabled-button "
                      : "commun-reset-button "
                  }
                  text={"Delete Leave"}
                  onClick={()=>handleCancelClick()}
                />
                  </div>
                 
                  </>
                ) : (
                  ""
                )}
             
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default FormLeaveRequest;
